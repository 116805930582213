export const Filters = [
  { name: 'Original', value: null },
  { name: '1977', value: "_1977" },
  { name: 'Aden', value: "aden" },
  { name: 'Brannan', value: "brannan" },
  { name: 'Brooklyn', value: "brooklyn" },
  { name: 'Clarendon', value: "clarendon" },
  { name: 'Earlybird', value: "earlybird" },
  { name: 'Gingham', value: "gingham" },
  { name: 'Hudson', value: "hudson" },
  { name: 'Inkwell', value: "inkwell" },
  { name: 'Kelvin', value: "kelvin" },
  { name: 'Lark', value: "lark" },
  { name: 'Lo-Fi', value: "lofi" },
  { name: 'Maven', value: "maven" },
  { name: 'Mayfair', value: "mayfair" },
  { name: 'Moon', value: "moon" },
  { name: 'Nashville', value: "nashville" },
  { name: 'Perpetua', value: "perpetua" },
  { name: 'Reyes', value: "reyes" },
  { name: 'Rise', value: "rise" },
  { name: 'Slumber', value: "slumber" },
  { name: 'Stinson', value: "stinson" },
  { name: 'Toaster', value: "toaster" },
  { name: 'Valencia', value: "valencia" },
  { name: 'Walden', value: "walden" },
  { name: 'Willow', value: "willow" },
  { name: 'X-pro II', value: "xpro2" },
];
